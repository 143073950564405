import * as signalR from "@microsoft/signalr";
import { Constants } from "../constants/Common/Constants";
// import { msalInstance } from "..";

//let url = 'https://localhost:7084/' + `versionHub`;
let url = `${Constants.baseAPIUrl}versionHub`;

const connection = new signalR.HubConnectionBuilder()
    .withUrl(url,
        //     {
        //     accessTokenFactory: () => msalInstance.accessToken
        // }
    )
    .withAutomaticReconnect()
    .build();

connection.start().catch(err => console.error(err.toString()));
// if (msalInstance.accessToken) {
//     connection.start().catch(err => console.error(err.toString()));
// }

export default connection;
