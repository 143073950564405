import { propertyOf } from "../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../RAFComponents/models/Common/RAFEntityBase";

export enum RAFFocusUserType {
    User = 'User',
    Portal = 'Portal',
}

export class IFocusDto {
    UID?: string;
    FocusUser?: string;
    FocusUserUID?: string;
    RelatedTo?: string;
    FocusUserType?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    FocusOrder?: number;
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}

export class IFocusRow {
    UID?: string;
    FocusUser?: string;
    FocusUserUID?: string;
    RelatedTo?: string;
    FocusUserType?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    FocusOrder?: number;
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}

export class FocusRow extends IFocusRow implements RAFEntityBase {
    //UserName?: string;

    getClassName?() {
        return 'user_focus';
    }
    getIdField?(): string {
        return propertyOf<FocusRow>("UID");
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "UserFocus/List";
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "UserFocus/Save";
    }
    getLookupUrl(): string {//use route name(entity displayname)
        return "UserFocus/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}










